import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

import Layout from '../components/layout'
import SEO from '../components/seo'
import * as styles from './generic-page.module.css'

const WikiPage = ({ data }) => {
  const page = data.strapiPage
  const body = page.body?.data?.childMarkdownRemark?.html || ''
  return (
    <Layout
      breadcrumbText={page.header?.label}
      breadcrumbLink={page.header?.link}
    >
      <SEO title={page.title} pageSEO={page.SEO} />
      <div className="page-content page-content--grow">
        <div className={styles.genericPage}>
          {body && (
            <div
              dangerouslySetInnerHTML={{
                __html: body,
              }}
            ></div>
          )}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query PageQuery($slug: String!) {
    strapiPage(slug: { eq: $slug }) {
      title
      body {
        data {
          childMarkdownRemark {
            html
          }
        }
      }
      header {
        label
        link
      }
      SEO {
        keywords
        metaDescription
        shareImage {
          alternativeText
          ext
          hash
          height
          mime
          name
          size
          url
          width
        }
      }
    }
  }
`

export default WikiPage
